import { getSubscriptionConfig } from '../config/subscriptions-config';

const calculateSubscriptionDiscountPrice = ({
  price,
  isFirstOrder,
  env,
  shopBrand,
  regionCode,
  productBrandId,
}: {
  price: number;
  isFirstOrder: boolean;
  env: string;
  shopBrand: ShopBrand;
  regionCode: RegionCode;
  productBrandId?: number;
}): number => {
  const subscriptionConfig = getSubscriptionConfig({
    env,
    shopBrand,
    regionCode,
    productBrandId,
  });

  const discountPercentage = isFirstOrder
    ? subscriptionConfig?.discountPercentage.firstOrder
    : subscriptionConfig?.discountPercentage.normal;

  if (!discountPercentage) {
    return price;
  }

  return price * (1 - discountPercentage / 100);
};

export { calculateSubscriptionDiscountPrice };
